import { lazy } from 'react'
import { RouteProps } from 'react-router'
// Public
const Authenticate = lazy(() => import('../containers/Authenticate'))
// Private
const Dashboard = lazy(() => import('../containers/Dashboard'))
const StorageTypeSetup = lazy(() => import('../containers/StorageTypeSetup'))
const UserSetup = lazy(() => import('../containers/UserSetup'))
const WarehouseSetup = lazy(() => import('../containers/WarehouseSetup'))
const OrderEntry = lazy(() => import('../containers/OrderEntry'))

export interface PrivateRouteObject extends RouteProps {
  exact: boolean
  path: string
  breadcrumb: string
  component: any
  title: string
}

const PRIVATE_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    path: '/',
    breadcrumb: 'Dashboard',
    component: Dashboard,
    title: 'Dashboard'
  },
  {
    exact: true,
    path: '/storage-type-setup',
    breadcrumb: 'Storage Type Setup',
    component: StorageTypeSetup,
    title: 'Storage Type Setup'
  },
  {
    exact: true,
    path: '/user-setup',
    breadcrumb: 'User Setup',
    component: UserSetup,
    title: 'User Setup'
  },
  {
    exact: true,
    path: '/warehouse-setup',
    breadcrumb: 'Warehouse Setup',
    component: WarehouseSetup,
    title: 'Warehouse Setup'
  },
  {
    exact: true,
    path: '/order-entry',
    breadcrumb: 'Order Entry',
    component: OrderEntry,
    title: 'Order Entry'
  }
]

const PUBLIC_ROUTES = [
  {
    exact: true,
    path: '/authenticate',
    component: Authenticate,
    title: 'Authenticate'
  }
]

export { PUBLIC_ROUTES, PRIVATE_ROUTES }
