import { Avatar, Box, Flex } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { Field, Form, Formik } from 'formik'
import * as React from 'react'
import { Bell, Search } from 'react-feather'
import { useMediaQuery } from 'react-responsive'
import { RouteComponentProps, withRouter } from 'react-router'
import {
  color,
  ColorProps,
  justifyContent,
  left,
  LeftProps,
  space,
  SpaceProps
} from 'styled-system'
import { ConnectedSelect, DropdownMenu } from '..'
import { useAppContext } from '../../contexts/ApplicationProvider.context'
import { useAuthentication } from '../../hooks'
import { logger } from '../../utils'
import HamburgerIcon from '../SideBar/icon'
import { H5 } from '../Typography'

const INITIAL_VALUES = {
  productLocation: '',
  stockOwner: ''
}

type HeaderProps = RouteComponentProps &
  ColorProps & {
    color?: string
    size?: number
    id?: string
    open?: boolean
    getLoggedInUser?: () => { name?: string; id: string }
  }

type HeaderContProps = LeftProps &
  SpaceProps &
  ColorProps & {
    color?: string
    open?: boolean
  } & {
    justifyContent: string
  }

export const HeaderCont = styled.div<HeaderContProps>`
  top: 0;
  right: 0;
  height: 60px;
  z-index: 1300;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  border-bottom-width: 1px;
  justify-content: space-between;
  left: ${(props) => (props.open ? '250px' : '60px')};
  @media screen and (max-width: 40em) {
    left: 0;
  }
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${justifyContent}
  ${left}
  ${space};
  ${color};
`

export const MobileHeaderCont = styled.div<HeaderContProps>`
  top: 60px;
  right: 0;
  height: 60px;
  z-index: 1300;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  border-bottom-width: 1px;
  justify-content: space-between;
  left: ${(props) => (props.open ? '250px' : '60px')};
  @media screen and (max-width: 40em) {
    left: 0;
  }
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${justifyContent}
  ${left}
  ${space};
  ${color};
`

const Header: React.FC<HeaderProps> = ({ ...rest }) => {
  const initialValues = {
    ...INITIAL_VALUES
  }

  const { drawerOpen, toggleDrawer } = useAppContext()
  const { cognitoUser, signOut } = useAuthentication()
  const cognitoUserFullName = cognitoUser?.user?.firstName
    ? cognitoUser.user.firstName + ' ' + cognitoUser.user.lastName
    : ''
  const locationMenuItems = [{ label: 'Warehouse', value: 'Warehouse' }]
  const stockOwnerMenuItems = [
    { label: 'Faron', value: 'Faron' },
    { label: 'Thando', value: 'Thando' }
  ]

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const isTablet = useMediaQuery({ query: '(max-width: 50em)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 1366px)' })
  const showHeaderContent = isTabletOrMobile ? !drawerOpen : true

  const handleResize = () => {
    window.innerWidth < 1366 ? toggleDrawer(false) : toggleDrawer(true)
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    //vs throws error if dependency is added
    //eslint-disable-next-line
  }, [])

  const renderForm = () => {
    return (
      <Formik initialValues={initialValues} onSubmit={() => logger('Form Submitted')}>
        <Form style={{ width: '100%' }}>
          <Flex flexDirection="row" justifyContent="center" alignItems="center" flex={1}>
            <Field
              name="productLocation"
              placeholder="Product Location"
              options={locationMenuItems}
              mr={4}
              minWidth="auto"
              component={ConnectedSelect}
            />
            <Field
              name="stockOwner"
              placeholder="Select Stock Owner"
              options={stockOwnerMenuItems}
              mr={4}
              minWidth="auto"
              component={ConnectedSelect}
            />
          </Flex>
        </Form>
      </Formik>
    )
  }

  return (
    <React.Fragment>
      <HeaderCont px={4} {...rest} justifyContent={isTabletOrMobile ? 'flex-end' : 'space-between'}>
        <Flex flexDirection="row" flex={isDesktop ? 1 : 0}>
          <Flex flexDirection="row" justifyContent="center" alignItems="center">
            <HamburgerIcon
              ml={0}
              open={false}
              onClick={() => {
                toggleDrawer()
              }}
              bg={drawerOpen ? 'gray.400' : 'gray.800'}
            />
          </Flex>
        </Flex>
        {showHeaderContent && (
          <Flex flexDirection="row" flex={2} justifyContent="space-evenly">
            <Flex
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              flex={2}
              marginLeft={2}
            >
              {!isTabletOrMobile && renderForm()}
            </Flex>
            <Flex flexDirection="row" justifyContent="center" alignItems="center">
              <Box mx={4}>
                <Search fontWeight={300} />
              </Box>
              <Box mx={4}>
                <Bell fontWeight={300} />
              </Box>
            </Flex>

            <Flex flexDirection="row" justifyContent="center" alignItems="center">
              <DropdownMenu
                menuItems={[{ title: 'Logout', onClick: () => signOut() }]}
                title={cognitoUserFullName}
                bg={'solid.white'}
                icon={
                  <React.Fragment>
                    <Avatar bg="brand.500" size="sm" name={cognitoUserFullName} src="../" />
                    {!isTablet && (
                      <H5 mb={0} ml={4} fontSize="md" fontWeight={300}>
                        {cognitoUserFullName}
                      </H5>
                    )}
                  </React.Fragment>
                }
              />
            </Flex>
          </Flex>
        )}
      </HeaderCont>
      {showHeaderContent && isTabletOrMobile && (
        <MobileHeaderCont px={4} {...rest} justifyContent="space-evenly">
          <Flex flex={1}>{renderForm()}</Flex>
        </MobileHeaderCont>
      )}
    </React.Fragment>
  )
}

export default withRouter(Header)

Header.defaultProps = {
  bg: 'white'
}
