import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import { logger } from '../utils'

type AppContextType = {
  drawerOpen: boolean
  toggleDrawer: (open?: boolean) => void
  displayPageHeader: boolean
  showPageHeader: (show: boolean) => void
  displayPageButton: {
    show: boolean
    text: string | null
    onClick: () => void
  }
  createPageButton: (show: boolean, text: string, onClick: () => void) => void
}

export const AppContext = React.createContext<AppContextType>({
  drawerOpen: false,
  toggleDrawer: () => null,
  displayPageHeader: true,
  showPageHeader: () => null,
  displayPageButton: {
    show: false,
    text: null,
    onClick: () => null
  },
  createPageButton: () => null
})

export const useAppContext = () => React.useContext(AppContext)

const AppProvider: React.FC<{}> = ({ children }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 50em)' })

  const [drawerOpen, setDrawerOpen] = React.useState(!isTabletOrMobile)
  const [displayPageHeader, setDisplayPageHeader] = React.useState(false)
  const [displayPageButton, setDisplayPageButton] = React.useState({
    show: false,
    text: 'Button',
    onClick: () => {
      logger('Defualt Click')
    }
  })

  function toggleDrawer(open?: boolean) {
    setDrawerOpen(open ? open : !drawerOpen)
  }

  function showPageHeader(show: boolean) {
    setDisplayPageHeader(show)
  }
  function createPageButton(show: boolean, text: string, onClick: () => void) {
    setDisplayPageButton({ show, text, onClick })
  }

  return (
    <AppContext.Provider
      value={{
        drawerOpen,
        toggleDrawer,
        displayPageHeader,
        showPageHeader,
        displayPageButton,
        createPageButton
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
