import { Flex } from '@chakra-ui/core'
import { Formik, FormikHelpers, FormikValues, isFunction, FormikProps } from 'formik'
import * as React from 'react'
import { StyledSystemProps } from '../../typings/StyledSystemProps'
import PageWrapFooter from './PageWrapFooter'
import PageWrapHeader from './PageWrapHeader'
import { Wrapper } from './styles'

type Props = StyledSystemProps & {
  backgroundImage?: string
  color?: string
  hideCTAButton?: boolean
  initialValues?: any
  showPageFooter?: boolean
  showPageHeader?: boolean
  onSubmit?: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => void | Promise<any>
}

/**
 * @render react
 * @name PageWrap component
 * @description The main wrapper component for pages/screens
 */

const PageWrap: React.FC<Props> = ({
  children,
  hideCTAButton,
  initialValues,
  onSubmit,
  showPageFooter,
  showPageHeader,
  ...rest
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => onSubmit && onSubmit(values, actions)}
    >
      {(formikProps) => {
        const pageChildren = isFunction(children)
          ? (children as (props: FormikProps<FormikValues>) => React.ReactNode)(formikProps)
          : children

        return (
          <Wrapper {...rest}>
            <fieldset disabled={formikProps.isSubmitting}>
              {showPageHeader && <PageWrapHeader hideCTAButton={hideCTAButton} />}
              {/* 
            // @ts-ignore - Types of property 'borderTopWidth' are incompatible. */}
              <Flex {...rest} p={4} width="100%">
                {pageChildren}
              </Flex>
              {showPageFooter && <PageWrapFooter />}
            </fieldset>
          </Wrapper>
        )
      }}
    </Formik>
  )
}

PageWrap.defaultProps = {
  pb: '60px',
  flex: 1,
  bg: 'gray.50',
  flexDirection: 'column',
  pt: '60px',
  justifyContent: 'flex-start',
  initialValues: {},
  hideCTAButton: true
}

export default PageWrap
