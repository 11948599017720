import { Flex, Input, Text } from '@chakra-ui/core'
import { InputProps } from '@chakra-ui/core/dist/Input'
import { ErrorMessage, FieldProps } from 'formik'
import * as React from 'react'
import { Label, LabelProps } from '../styles'

export type ConnectedFormGroupProps = LabelProps &
  FieldProps &
  InputProps & {
    label?: string
    labelMinWidth?: string
    labelMarginRight?: string
    updateOnBlur?: string
  }

const ConnectedFormGroup: React.FC<ConnectedFormGroupProps> = ({
  type,
  label,
  field,
  placeholder,
  flexDirection,
  labelMinWidth,
  labelMarginRight,
  updateOnBlur,
  form,
  defaultValue,
  ...rest
}) => (
  <Flex
    flexDirection={flexDirection || 'column'}
    width="100%"
    mr={rest.mr}
    ml={rest.ml}
    mt={rest.mt}
    mb={rest.mb}
  >
    {label && (
      <Label htmlFor={field.name} minWidth={labelMinWidth} marginRight={labelMarginRight}>
        {label}
      </Label>
    )}

    {/* 
    //! NOTE: 
    //! updateOnBlur is needed as field and rest cause the input to not respond when onChange and onBlur are provided
    //! Formik provides FastField which does so this is an optimization to improve typing speed on larege forms
    */}
    {!updateOnBlur && (
      <Input {...field} type={type} id={field.name} placeholder={placeholder || label} {...rest} />
    )}
    {updateOnBlur && (
      <Input
        type={type}
        id={field.name}
        placeholder={placeholder || label}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()}
        onBlur={({ currentTarget }: React.ChangeEvent<HTMLInputElement>) =>
          form.setFieldValue(field.name, currentTarget.value)
        }
        defaultValue={defaultValue}
      />
    )}
    <ErrorMessage name={field.name}>
      {(msg) => (
        <Text color="red.500" textAlign="right">
          {msg}
        </Text>
      )}
    </ErrorMessage>
  </Flex>
)

export default ConnectedFormGroup

ConnectedFormGroup.defaultProps = {
  mb: 2,
  type: 'text',
  fontWeight: 'lighter'
}
