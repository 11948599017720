import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { getCognitoUserToken } from './utils'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_MACS_GRAPHQL_ENDPOINT
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from (local/session) storage if it exists
  const token = getCognitoUserToken()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

export default client
