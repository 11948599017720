import { Button, Flex } from '@chakra-ui/core'
import styled from '@emotion/styled'

export const BreadCrumbCont = styled(Flex)`
  @media screen and (max-width: 40em) {
    display: none;
  }
`

export const CTAButton = styled(Button)``
