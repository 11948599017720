import { Button, Flex } from '@chakra-ui/core'
import { useFormikContext } from 'formik'
import React from 'react'

/**
 * @render react
 * @name PageWrapFooter component
 * @description Footer for a page/screen that consumes PageWrap
 */

const PageWrapFooter = () => {
  const { isSubmitting, handleSubmit, resetForm } = useFormikContext()

  return (
    <Flex
      background="white"
      position="fixed"
      justifyContent="flex-end"
      bottom={0}
      left={0}
      padding={4}
      width="100%"
    >
      <Button mr={4} onClick={() => resetForm()} marginRight={2} variant="outline">
        Delete
      </Button>
      <Button mr={4} onClick={() => resetForm()} marginRight={2} variant="outline">
        Clear
      </Button>
      <Button
        mr={4}
        bg="solid.primary"
        color="solid.white"
        isLoading={isSubmitting}
        onClick={handleSubmit}
      >
        Save
      </Button>
    </Flex>
  )
}

export default PageWrapFooter
