import { Log, UserManager } from 'oidc-client'
import React, { FC, useEffect, useState } from 'react'
import { COGNITO_USER_STORAGE_KEY, IDENTITY_SERVER } from '../constants'
import { useLocalStorage } from '../hooks'

type CognitoUser = {
  jwt: string
  // TODO: Provide better types
  user: any
}

type ContextType = {
  authenticating: boolean
  cognitoUser: CognitoUser
  isAuthenticated: boolean
  signInRedirect: () => void
  signOut: () => void
  setAuthenticated: (_status: boolean) => void
}

const DEFAULT_STATE = {
  authenticating: true,
  isAuthenticated: false,
  cognitoUser: {} as CognitoUser,
  signInRedirect: () => {},
  signOut: () => {},
  setAuthenticated: (_status: boolean) => {}
}

export const AuthenticationContext = React.createContext<ContextType>(DEFAULT_STATE)

const settings = {
  authority: IDENTITY_SERVER.stsAuthority,
  client_id: IDENTITY_SERVER.clientId,
  redirect_uri: `${IDENTITY_SERVER.clientRoot}signin-callback.html`,
  silent_redirect_uri: `${IDENTITY_SERVER.clientRoot}silent-renew.html`,
  post_logout_redirect_uri: `${IDENTITY_SERVER.clientRoot}`,
  response_type: 'code',
  scope: IDENTITY_SERVER.clientScope
}

const userManager = new UserManager(settings)

const Provider: FC = ({ children }) => {
  const [localUser, setLocalUser, clearLocalUser] = useLocalStorage(COGNITO_USER_STORAGE_KEY)

  const [authenticating, setAuthenticating] = useState(DEFAULT_STATE.authenticating)
  const [isAuthenticated, setIsAuthenticated] = useState(DEFAULT_STATE.isAuthenticated)
  const [cognitoUser, setCognitoUser] = useState(localUser)

  const signInRedirect = () => userManager.signinRedirect()
  const signOut = () => userManager.signoutRedirect().then(() => clearLocalUser())

  useEffect(() => {
    Log.logger = console
    Log.level = Log.INFO

    userManager.getUser().then((user) => {
      if (user) {
        const cognitoUser = {
          jwt: user.access_token,
          user: {}
        }

        setLocalUser(cognitoUser)
        setCognitoUser(cognitoUser)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (cognitoUser) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }

    setAuthenticating(false)
  }, [cognitoUser])

  return (
    <AuthenticationContext.Provider
      value={{
        authenticating,
        cognitoUser,
        isAuthenticated,
        setAuthenticated: setIsAuthenticated,
        signInRedirect,
        signOut
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export default Provider
