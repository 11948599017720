import styled from '@emotion/styled'
import {
  alignItems,
  color,
  flex,
  flexDirection,
  flexWrap,
  height,
  justifyContent,
  maxHeight,
  space,
  width
} from 'styled-system'

export const Wrapper = styled.div`
  ${flex};
  ${space};
  ${color};
  ${width};
  ${height};
  ${flexWrap};
  ${maxHeight};
  ${alignItems};
  ${flexDirection};
  ${justifyContent};
  display: flex;
  box-sizing: border-box;
  min-height: calc(100vh - 60px);
  position: relative;
`
