import { Flex, Image } from '@chakra-ui/core'
import React from 'react'
import { Breadcrumbs } from '../../../components'
import { useAppContext } from '../../../contexts/ApplicationProvider.context'
import images from '../../../theme/images'
import { StyledSystemProps } from '../../../typings/StyledSystemProps'
import { BreadCrumbCont, CTAButton } from './styles'

type PageWrapHeaderProps = StyledSystemProps & {
  hideCTAButton?: boolean
}

/**
 * @render react
 * @name PageWrapFooter component
 * @description header for a page/screen that consumes PageWrap
 */

const PageWrapHeader: React.FC<PageWrapHeaderProps> = ({ hideCTAButton, ...rest }) => {
  // TODO: We might not need too use AppContext for this
  const { displayPageButton } = useAppContext()

  return (
    // @ts-ignore - Types of property 'borderTopWidth' are incompatible.
    <Flex alignItems="center" height="160px" justifyContent="space-between" px={4} {...rest}>
      <Flex flex={1}>
        <BreadCrumbCont>
          <Breadcrumbs />
        </BreadCrumbCont>
      </Flex>
      <Flex flex={1} justifyContent="center">
        <Image src={images.appLogo} height="64px" width="auto" />
      </Flex>
      <Flex flex={1} justifyContent="flex-end">
        {/* //!FIX: created my own them color for this as if i use solid white it appends .500 to the end of the color  */}
        {!hideCTAButton && (
          <CTAButton variantColor="pgHeaderBtn" color="black" onClick={displayPageButton.onClick}>
            {displayPageButton.text}
          </CTAButton>
        )}
      </Flex>
    </Flex>
  )
}

export default PageWrapHeader

PageWrapHeader.defaultProps = {
  bg: '#6dd2c4',
  color: 'white',
  fontSize: '20px'
}
