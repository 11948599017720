import { Text } from '@chakra-ui/core'
import moment from 'moment'
import React, { FC } from 'react'
import { FontSizeProps } from 'styled-system'

// TODO: Get correct formatting for relative times
moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: '%dmin',
    mm: '%dmins',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
  }
})

const DateTime: FC<{ date: string; showFullDate?: boolean } & FontSizeProps> = ({
  date,
  showFullDate,
  ...rest
}) => {
  let formattedDateTime = moment(date).fromNow(true)

  if (showFullDate) {
    const formattedTime = moment(date).format('hh:mm')
    const formattedDate = moment(date).format('D MMMM YYYY')

    formattedDateTime = formattedTime + ' • ' + formattedDate
  }

  return <Text {...rest}>{formattedDateTime}</Text>
}

export default DateTime
